import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DefaultContent from "../components/Global/DefaultContent/defaultContent"
import styles from "../components/Home/04-Сases/cases.module.sass"
import GithubIcon from "../icons/github.svg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <DefaultContent>
      <h1>Ooooooops...</h1>
      <p style={{ fontSize: "1.286rem", fontWeight: 300, lineHeight: 1.67 }}>
        You just hit a route that doesn&#39;t exist...
      </p>
      <div className={styles.bottom + ' ' + styles.bottom404}>
        <span>Check out our</span>
        <a
          href="https://github.com/madfish-solutions"
          target="_blank"
          className="button button-secondary"
          rel="noopener noreferrer"
        >
          <span className={styles.withIcon + " content"}>
            <GithubIcon />
            Open-Source projects
          </span>
        </a>
        <span>or</span>
        <Link
          to="/#contactsScreen"
          className="button button-primary"
          rel="noopener noreferrer"
        >
          <span className="content">Contact Us</span>
        </Link>
      </div>
    </DefaultContent>
  </Layout>
)

export default NotFoundPage
